// @ts-strict-ignore

import * as React from 'react';
import cx from 'classnames';
import { compact } from 'lodash';

import {
  Link, Route, Redirect, Switch,
  useLocation, useHistory,
} from 'react-router-dom';

import { Breadcrumb, Menu } from 'antd';
import {
  TeamOutlined, ContactsOutlined,
  RightOutlined, ApartmentOutlined,
  ProfileOutlined, AppstoreOutlined,
  ToolOutlined,
} from '@ant-design/icons';

import { logger } from '@common';
import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';
import { UserMenu } from './UserMenu';
import styles from './Home.scss';

const { SubMenu } = Menu;

const { lazy, useState, useLayoutEffect } = React;

const ClientsView = lazy(() => import('./ClientsView/ClientsView'));
const UsersView = lazy(() => import('./UsersView/UsersView'));
const RolesView = lazy(() => import('./RolesView/RolesView'));
const OrganizationsView = lazy(() => import('./OrganizationsView/OrganizationsView'));
const ApplicationsView = lazy(() => import('./ApplicationsView/ApplicationsView'));
const CommunitiesView = lazy(() => import('./CommunitiesView/CommunitiesView'));
const ClientFeaturesView = lazy(() => import('./ClientFeaturesView/ClientFeaturesView'));
const AdminToolsView = lazy(() => import('./AdminToolsView/AdminToolsView'));
const ProgramDetail = lazy(() => import('./CommunitiesView/ProgramsDetailView/ProgramDetails'));
const ReportingView = lazy(() => import('./ReportingView/ReportingView'));

interface IProps {
  token: string;
  targetUrl: string;
  logout(): void;

  className?: string;
}

const breadcrumbNameMap = {
  clients: 'Clients',
  users: 'Users',
  roles: 'Roles',
  orgs: 'Organizations',
  apps: 'Applications',
  communities: 'Communities',
  programs: 'Programs',
  client_groups: 'Client Groups',
  members: 'Members',
  details: 'Details',
  client_features: 'Client Features',
  reporting: 'Reporting',
  admin_tools: 'Admin Tools',
};

/**
 * @type {React.FunctionComponent}
 */
export const Home = React.memo((props: IProps) => {
  const location = useLocation();
  const history = useHistory();
  const [openKeys, setOpenKeys] = useState([]);

  // redirects to target url before authenticated
  useLayoutEffect(() => {
    if (props.targetUrl && props.targetUrl !== location.pathname) {
      history.replace(props.targetUrl);
    }
  }, [props.targetUrl, history, location.pathname]);

  const pathSnippets = compact(location.pathname.split('/'));
  const extraBreadcrumbItems = pathSnippets.map((path, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    logger.debug(path, breadcrumbNameMap[path]);
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[path] || path}</Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <div className={cx(styles.Home, props.className)}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.logo} />
          <UserMenu className={styles.userMenu} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.nav}>
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={setOpenKeys}
            style={{ borderRight: 'none' }}
            className={styles.menu}
          >
            <Menu.Item key="organizations">
              <Link className={styles.link} to="/orgs">
                <ApartmentOutlined className={styles.icon} />
                Organizations
              </Link>
            </Menu.Item>
            <Menu.Item key="applications">
              <Link className={styles.link} to="/apps">
                <AppstoreOutlined className={styles.icon} />
                Applications
              </Link>
            </Menu.Item>
            <Menu.Item key="clients">
              <Link className={styles.link} to="/clients">
                <ContactsOutlined className={styles.icon} />
                Clients
              </Link>
            </Menu.Item>
            <Menu.Item key="communities">
              <Link className={styles.link} to="/communities">
                <TeamOutlined className={styles.icon} />
                Communities
              </Link>
            </Menu.Item>
            <SubMenu
              key="usersAndRoles"
              title={(
                <span>
                  <RightOutlined />
                  <span>Users & Roles</span>
                </span>
              )}
            >
              <Menu.Item key="users">
                <Link className={styles.link} to="/users">
                  <TeamOutlined className={styles.icon} />
                  Users
                </Link>
              </Menu.Item>
              <Menu.Item key="roles">
                <Link className={styles.link} to="/roles">
                  <ProfileOutlined className={styles.icon} />
                  Roles
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="client_features">
              <Link className={styles.link} to="/client_features">
                <TeamOutlined className={styles.icon} />
                Client Features
              </Link>
            </Menu.Item>
            <Menu.Item key="reporting">
              <Link className={styles.link} to="/reporting">
                <TeamOutlined className={styles.icon} />
                Reporting
              </Link>
            </Menu.Item>
            <Menu.Item key="admin_tools">
              <Link className={styles.link} to="/admin_tools">
                <ToolOutlined className={styles.icon} />
                Admin Tools
              </Link>
            </Menu.Item>
          </Menu>
        </div>
        <div className={styles.children}>
          <Breadcrumb className={styles.breadcrumb}>{breadcrumbItems}</Breadcrumb>
          <Switch>
            <Route path="/clients" render={lazyLoadComponent(ClientsView)} />
            <Route path="/users" render={lazyLoadComponent(UsersView)} />
            <Route path="/roles" render={lazyLoadComponent(RolesView)} />
            <Route path="/orgs" render={lazyLoadComponent(OrganizationsView)} />
            <Route path="/apps" render={lazyLoadComponent(ApplicationsView)} />
            <Route path="/communities" render={lazyLoadComponent(CommunitiesView)} />
            <Route path="/client_features" render={lazyLoadComponent(ClientFeaturesView)} />
            <Route path="/admin_tools" render={lazyLoadComponent(AdminToolsView)} />
            <Route path="/programs/:programId" render={lazyLoadComponent(ProgramDetail)} />
            <Route path="/reporting" render={lazyLoadComponent(ReportingView)} />
            <Redirect from="/" to="/clients" />
          </Switch>
        </div>
      </div>
    </div>
  );
});
