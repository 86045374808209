import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const ADD_FEATURES_TO_CLIENTS = gql`
  mutation AddFeaturesToClients($clientIds: [String!]!, $featureIds: [Int!]!) {
    addFeaturesToClients(clientIds: $clientIds, featureIds: $featureIds)
  }
`;

export const useAddFeaturesToClients = () => {
  const [addFeaturesToClients, { loading, error }] = useMutation(ADD_FEATURES_TO_CLIENTS);

  return {
    addFeaturesToClients,
    loading,
    error,
  };
};
