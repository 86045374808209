// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from 'react';
import cx from 'classnames';
import { toNumber } from 'lodash';
import {
  Table,
  Modal, Typography,
  Input, Button, Select,
} from 'antd';
import {
  useHistory, useRouteMatch,
} from 'react-router-dom';

import {
  IEmbeddedReportingItem,
  useDeleteEmbeddedReportingItemById,
  useGetEmbeddedReportingItems,
  useSaveEmbeddedReportingItem,
  useGetAdminClientId,
} from '@frontend/app/hooks';
import styles from './ReportingListView.scss';

const { Option } = Select;
const { Column } = Table;
const { Text, Title } = Typography;
const { useState } = React;

interface IMatchParams {
  clientId: string;
}
interface IProps {
  className?: string;
}

const MAX_POSITION = 10;

/**
 * @type {React.FunctionComponent}
 */
const ReportingListView: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const adminClientId = useGetAdminClientId();
  const context = {
    headers: {
      'requested-client-id': adminClientId,
    },
  };
  const {
    data: { embeddedReportingItems } = {}, loading: loadingEmbeddedReportingItems, refetch, error: loadingItemsError,
  } = useGetEmbeddedReportingItems({ context, skip: !adminClientId });
  const [saveEmbeddedReportingItem, { loading: loadingSaveEmbeddedReportingItem, error: saveEmbeddedReportingItemError }] = useSaveEmbeddedReportingItem({ context });
  const [deleteEmbeddedReportingItem, { loading: loadingDeleteEmbeddedReportingItem }] = useDeleteEmbeddedReportingItemById({ context });
  const [showAddOrEditModal, setShowAddOrEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IEmbeddedReportingItem | null>(null);
  const [itemInput, setItemInput] = useState<IEmbeddedReportingItem | null>(null);

  return (
    <div className={cx(styles.ReportingListView, props.className)}>
      <div className={styles.header}>
        <Title>Reporting</Title>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={() => {
            setSelectedItem(null);
            setItemInput(null);
            setShowAddOrEditModal(true);
          }}
        >
          Add Embedded Reporting Item
        </Button>
      </div>
      {
        loadingItemsError
        && (
          <Text type="danger">
            Error while loading embedded reporting items:
            {loadingItemsError?.message}
          </Text>
        )
      }
      <Table
        rowKey={(record) => record.id}
        dataSource={embeddedReportingItems}
        loading={
          loadingEmbeddedReportingItems
          || loadingSaveEmbeddedReportingItem
          || loadingDeleteEmbeddedReportingItem
        }
        className={styles.table}
      >
        <Column
          title="Name"
          dataIndex="name"
          render={(_, item: IEmbeddedReportingItem) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <span
              type="button"
              className={styles.nameCell}
              onClick={() => history.push(`${match.url}/${item.id}`)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  history.push(`${match.url}/${item.id}`);
                }
              }}
            >
              {item.name}
            </span>
          )}
        />
        <Column title="ID" dataIndex="id" />
        <Column
          title="URL"
          dataIndex="url"
        />
        <Column
          title="Position"
          dataIndex="position"
        />
        <Column
          title="Edit"
          key="edit"
          render={(item: IEmbeddedReportingItem) => (
            <span>
              <Button
                type="primary"
                onClick={() => {
                  setSelectedItem(item);
                  // Clone object to avoid changing the original object
                  setItemInput({ ...item });
                  setShowAddOrEditModal(true);
                }}
              >
                Edit
              </Button>
            </span>
          )}
        />
        <Column
          title="Delete"
          key="delete"
          render={(item: IEmbeddedReportingItem) => (
            <span>
              <Button
                // Fresh! doesn't have a danger button, so we use antd version
                type="danger"
                onClick={async () => {
                  await deleteEmbeddedReportingItem({
                    variables: {
                      id: item.id,
                    },
                  });
                  refetch();
                }}
              >
                Delete
              </Button>
            </span>
          )}
        />
      </Table>
      <Modal
        className={styles.AddOrEditModal}
        title={selectedItem ? 'Edit Embedded Reporting Item' : 'Add Embedded Reporting Item'}
        visible={showAddOrEditModal}
        confirmLoading={loadingSaveEmbeddedReportingItem}
        onOk={async () => {
          await saveEmbeddedReportingItem({
            variables: {
              input: {
                name: itemInput.name,
                url: itemInput.url,
                position: itemInput.position,
                ...(selectedItem && { id: selectedItem.id }),
              },
            },
          });
          setShowAddOrEditModal(false);
          refetch();
        }}
        onCancel={() => setShowAddOrEditModal(false)}
      >
        {
          saveEmbeddedReportingItemError && (
            <Text type="danger">{saveEmbeddedReportingItemError.message}</Text>
          )
        }
        {selectedItem && (
          <>
            <Text className={styles.inputLabel} ellipsis>ID</Text>
            <Input className={styles.input} placeholder="ID" value={selectedItem.id} disabled />
          </>
        )}
        <Text className={styles.inputLabel} ellipsis>Name</Text>
        <Input
          className={styles.input}
          placeholder="Name"
          value={itemInput?.name}
          onChange={
            (e) => setItemInput({ ...itemInput, name: e.target.value })
          }
          required
        />
        <Text className={styles.inputLabel} ellipsis>URL</Text>
        <Input
          className={styles.input}
          placeholder="URL"
          value={itemInput?.url}
          onChange={
            (e) => setItemInput({ ...itemInput, url: e.target.value })
          }
          required
        />
        <Text className={styles.inputLabel} ellipsis>Position</Text>
        <Select
          className={styles.select}
          placeholder={itemInput?.position || 'Please select a position'}
          value={itemInput?.position}
          onChange={
            (position) => setItemInput({ ...itemInput, position: toNumber(position) })
          }
        >
          {
            Array.from({ length: MAX_POSITION }, (_, i) => (
              <Option key={i} value={i}>{i}</Option>
            ))
          }
        </Select>
      </Modal>
    </div>
  );
});

export default ReportingListView;
