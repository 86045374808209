import gql from 'graphql-tag';

export const GET_MEMBERS_QUERY = gql`
  query GetMembersQuery {
    members {
      id, name, email
    }
  }
`;

export const UPLOAD_MEMBERS_CSV = gql`
  mutation UploadMembersCSV($file: Upload!){
    uploadMemberCSV(file: $file)
  }
`;

export const DELETE_CREATOR_FROM_ASPIRE = gql`
  mutation DeleteCreatorFromAspire($email: String!) {
    deleteCreatorFromAspire(email: $email)
  }
`;

export const FIND_MEMBERS_BY_EMAIL = gql`
  query FindMembersByEmail($email: String!) {
    findMembersByEmail(email: $email) {
      id, name, email
    }
  }
`;
