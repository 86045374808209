import * as React from 'react';
import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Button, DatePicker, message, Typography } from 'antd'
import moment, { Moment } from 'moment';
const { RangePicker } = DatePicker;
interface FetchStatsProps {
  clientId: string;
}

const FORCE_FETCH_TUNE_STATS = gql`
    mutation ForceFetchTuneStats($clientId: String!, $startDate: DateTime!, $endDate: DateTime) {
      forceFetchTuneStats(clientId: $clientId, startDate: $startDate, endDate: $endDate)
    }
  `;
const FetchStats = ({ clientId }: FetchStatsProps) => {

  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([null, null]);

  const [forceFetchTuneStats] = useMutation(FORCE_FETCH_TUNE_STATS);  
  const onDateChange = (value: [Moment | null, Moment | null]) => {
    setDateRange(value);
  }
  const handleFetchStats = async () => {
    if (!dateRange[0]) return;
    try {
      await forceFetchTuneStats({
        variables: {
          clientId,
          startDate: dateRange[0],
          endDate: dateRange[1]
        }
      });
      message.success('Stats are being fetched in background, Please check in sometime');
    } catch (error) {
      console.error('Error fetching tune stats:', error);
    }
  };

  return (
    <div>
      <Typography.Title level={3}>Fetch Tune stats for this client</Typography.Title>
      <div>
      <RangePicker onChange={onDateChange} value={dateRange} disabledDate={(current) => current > moment()} />
      </div>
      <Button onClick={handleFetchStats} disabled={!dateRange[0]} style={{marginTop: '8px'}}>
        Fetch Stats
      </Button>
    </div>
  );
}

export default FetchStats;