import { logger } from '@common';
import { useGetClientMetadata } from '@frontend/app/hooks/useGetClientMetaData';
import { useSaveAllowedDomain } from '@frontend/app/hooks/useSaveAllowedDomain';
import { useRemoveAllowedDomain } from '@frontend/app/hooks/useRemoveAllowedDomain';
import { GET_CLIENT_META_DATA } from '@frontend/app/queries/getClientMetaDataQuery';
import { LoadSpinner } from '@frontend/lib';
import { Button, Form, Input, message, Modal, Row, Table, Typography } from 'antd';
import React from 'react';

interface IProps {
    className?: string;
    clientId: string;
}
const columns = [
    {
        title: 'Domain',
        dataIndex: 'domain',
    },
];

const AdditionalDomains: React.FC<IProps> = React.memo((props) => {
    const { clientId } = props;
    const [form] = Form.useForm();
    const [addDomainModal, setAddDomainModal] = React.useState(false);
    const [isFormValid, setIsFormValid] = React.useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const { data: clientMetaData, loading: clientMetaDataLoading } = useGetClientMetadata(clientId);
    const [saveAllowedDomain] = useSaveAllowedDomain(
        {
            onCompleted: () => {
                message.success('Domain added successfully');
                setSelectedRowKeys([]);
                setAddDomainModal(false);
                form.resetFields();
            },
            onError: (error) => {
                logger.error(error);
                message.error('Failed to save');
            },
            refetchQueries: [
                { query: GET_CLIENT_META_DATA, variables: { clientId: clientId } },
            ]
        }
    );
    const [removeAllowedDomains] = useRemoveAllowedDomain(
        {
            onCompleted: () => {
                message.success('Domain deleted successfully');
                setSelectedRowKeys([]);
                setAddDomainModal(false);
            },
            onError: (error) => {
                logger.error(error);
                message.error('Failed to delete');
            },
            refetchQueries: [
                { query: GET_CLIENT_META_DATA, variables: { clientId: clientId } },
            ]
        }
    );

    const handleAddDomain = async () => {
        await saveAllowedDomain(
            {
                variables: {
                    clientId,
                    allowedDomainInput: [form.getFieldValue('domain')],
                }
            }
        );
    };
    const handleDomainChange = () => {
        form.validateFields(['domain'])
            .then(() => {
                setIsFormValid(true);
            })
            .catch(() => {
                setIsFormValid(false);
            });
    };
    const rowSelection = {
        onChange: (newSelectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };
    if (clientMetaDataLoading) return <LoadSpinner />;
    const tableData = clientMetaData?.getClientMetadata?.allowedDomains ? clientMetaData?.getClientMetadata?.allowedDomains.map((url, index) => ({
        key: index,
        domain: url,
    })) : [];
    const handleDeleteDomains = async () => {
        const selectedDomains = tableData
            .filter(item => selectedRowKeys.includes(item.key))
            .map(item => item.domain);

        await removeAllowedDomains(
            {
                variables: {
                    clientId,
                    domains: selectedDomains,
                }
            }
        );
        setSelectedRowKeys([]);
    };


    return (
        <>
            <Row justify='space-between'>
                <Typography.Text type='secondary'>Add the additional domains for brands to use while creating deep links</Typography.Text>
                <Row style={{ gap: 8 }}>
                    <Button disabled={selectedRowKeys.length === 0} onClick={handleDeleteDomains}>Delete</Button>
                    <Button type="primary" onClick={() => setAddDomainModal(true)}>Add Domain</Button>
                </Row>
            </Row>


            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                columns={columns}
                dataSource={tableData}
            />

            <Modal
                title="Add Additional Domain"
                visible={addDomainModal}
                onOk={handleAddDomain}
                onCancel={() => setAddDomainModal(false)}
                okText="Add"
                okButtonProps={{ disabled: !isFormValid }}
            >
                <Form form={form} layout="vertical" >
                    <Form.Item name="domain" label="Domain" rules={[
                        { required: true, message: 'Please enter a domain' },
                        { type: 'url', message: 'Please enter a valid URL' },
                        {
                            validator: (_, value) => {
                                if (value && !value.startsWith('https://') && !value.startsWith('http://')) {
                                    return Promise.reject(new Error('URL must start with https:// or http://'));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}>
                        <Input placeholder="Enter Domain" onChange={handleDomainChange} />
                    </Form.Item>
                </Form>
            </Modal>

        </>
    );
});

export default AdditionalDomains;
