import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { GET_EMBEDDED_REPORTING_ITEM_BY_ID } from '@frontend/app/queries';
import {
  GetEmbeddedReportingItem,
} from '@frontend/app/queries/types/GetEmbeddedReportingItem';

export type IEmbeddedReportingItemWithToken = GetEmbeddedReportingItem;

type IOptions = QueryHookOptions<GetEmbeddedReportingItem>;

export function useGetEmbeddedReportingItem(id: number, options: IOptions = {}) {
  return useQuery<any>(
    GET_EMBEDDED_REPORTING_ITEM_BY_ID,
    {
      ...options,
      variables: {
        id,
      },
      skip: !id || options.skip,
      fetchPolicy: 'network-only',
    },
  );
}
