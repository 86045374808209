import {
  Skeleton,
  Alert,
  Button,
} from '@revfluence/fresh';
import { Typography } from 'antd';
import React from 'react';
import Helmet from 'react-helmet';
import {
  useGetEmbeddedReportingItem,
} from '@frontend/app/hooks';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { toNumber } from 'lodash';
import styles from './ReportingDetailView.scss';
// import { Header } from './components/Header';

const { Title } = Typography;

// Make sure that <tableau-viz> is accepted as a valid top-level DOM element in JSX/TSX without errors
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
      'tableau-viz': any;
    }
  }
}

interface IMatchParams {
  reportId: string;
}

export const ReportingDetailView: React.FC<RouteComponentProps> = () => {
  const { reportId } = useParams<IMatchParams>();
  const {
    data, loading, error, refetch,
  } = useGetEmbeddedReportingItem(toNumber(reportId));

  console.log('data', data);

  if (loading) {
    // Return Skeleton if loading
    return (
      <Skeleton
        active
        paragraph
      />
    );
  }

  if (!loading && error) {
    // Return error message if error
    return (
      <Alert
        action={(
          <Button
            onClick={() => refetch()}
            size="small"
            type="ghost"
          >
            Retry
          </Button>
        )}
        message="Oops! Something went wrong fetching this report."
        showIcon
        type="error"
      />
    );
  }

  return (
    <div>
      <Title>{data?.getEmbeddedReportingItemById?.name}</Title>
      <Helmet>
        <script async defer type="module" src="https://us-west-2b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js" />
      </Helmet>
      <div className={styles.tableauContainer}>
        <tableau-viz
          id="tableau-viz"
          src={data?.getEmbeddedReportingItemById?.url}
          hide-tabs
          toolbar="hidden"
          height="100%"
          token={data?.getEmbeddedReportingItemById?.token}
        />
      </div>
    </div>
  );
};

ReportingDetailView.defaultProps = {};

ReportingDetailView.displayName = 'ReportingDetailView';

export default ReportingDetailView;
