import * as React from 'react';
import cx from 'classnames';
import {
  map,
  toLower,
  toString,
  sortBy,
} from 'lodash';

import {
  Popconfirm, Space, Tooltip, Button,
  Table, Typography, Select,
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { LoadSpinner } from '@frontend/lib';

import { ClientFeatureState } from '@frontend/app/types/globalTypes';
import {
  IClientFeature,
  useGetClientFeatures,
  useSaveClientFeature,
  useDeleteClientFeatureById,
} from '@frontend/app/hooks';
import getColumnSearchProps from '@frontend/app/utils/getColumnSearchProps/getColumnSearchProps';
import { logger } from '@common';
import { EditFeatureDrawer } from './EditFeatureDrawer';
import BulkApplyFeatureDrawer from './BulkApplyFeatureDrawer';

import styles from './ClientFeatureList.scss';

interface IProps {
  className?: string;
}

const { useMemo, useCallback, useState } = React;
const { Column } = Table;
const { Title } = Typography;
const { Option } = Select;

/**
 * @type {React.FunctionComponent}
 */
const ClientFeatureList: React.FC<IProps> = React.memo((props) => {
  const { loading, features, refetch } = useGetClientFeatures();
  const { saveClientFeature, loading: saving } = useSaveClientFeature();
  const { deleteClientFeatureById, loading: deleting } = useDeleteClientFeatureById();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBulkApplyDrawer, setOpenBulkApplyDrawer] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<IClientFeature>(null);

  const onEditFeature = (feature: IClientFeature) => {
    setSelectedFeature(feature);
    setOpenDrawer(true);
  };
  const onBulkApplyFeature = (feature: IClientFeature) => {
    setSelectedFeature(feature);
    setOpenBulkApplyDrawer(true);
  };
  const onRequestClose = () => {
    setOpenDrawer(false);
    setSelectedFeature(null);
  };
  const onFeatureStateChange = useCallback(async (feature: IClientFeature, state: ClientFeatureState) => {
    await saveClientFeature({
      variables: {
        clientFeature: {
          id: feature.id,
          name: feature.name,
          description: feature.description,
          state,
        },
      },
    });
  }, [saveClientFeature]);

  const sortedFeatures = useMemo(() => sortBy(features, 'id'), [features]);

  logger.debug({
    loading,
    data: features,
  });

  return (
    <div className={cx(styles.ClientFeatureList, props.className)}>
      <div className={styles.header}>
        <Title>Client Features</Title>
        <Button
          className={styles.createButton}
          icon={<PlusOutlined />}
          type="primary"
          onMouseDown={() => setOpenDrawer(true)}
        >
          Create Client Feature
        </Button>
        <Button
          className={styles.bulkApplyButton}
          icon={<SolutionOutlined />}
          type="primary"
          onMouseDown={() => {
            setSelectedFeature(null);
            setOpenBulkApplyDrawer(true);
          }}
        >
          Bulk Apply LIMITED Feature
        </Button>
        <BulkApplyFeatureDrawer
          open={openBulkApplyDrawer}
          onRequestClose={() => setOpenBulkApplyDrawer(false)}
          features={features.filter((feature) => feature.state === ClientFeatureState.LIMITED)}
          selectedFeature={selectedFeature}
        />
        <EditFeatureDrawer
          open={openDrawer}
          onRequstClose={onRequestClose}
          isSubmitting={saving}
          onSubmit={(data) => {
            saveClientFeature({
              variables: {
                clientFeature: data,
              },
            }).then(() => {
              onRequestClose();
              // refetch list if new feature is added
              if (!data.id) {
                refetch();
              }
            }).catch((error) => {
              // Handle error case
              console.error('Error saving client feature:', error);
            });
          }}
          feature={selectedFeature}
        />
      </div>
      {
        loading && <LoadSpinner />
      }
      {
        !loading && (
          <Table
            rowKey={(record: IClientFeature) => toString(record.id)}
            dataSource={sortedFeatures}
            className={styles.table}
          >
            <Column key="id" title="Id" dataIndex="id" />
            <Column key="name" title="Name" dataIndex="name" {...getColumnSearchProps('name')} />
            <Column key="description" title="Description" dataIndex="description" ellipsis />
            <Column
              key="state"
              title="State"
              render={(_, feature: IClientFeature) => (
                <Select
                  disabled={saving}
                  value={feature.state}
                  onChange={(state) => onFeatureStateChange(feature, state)}
                >
                  {map(ClientFeatureState, (state) => (
                    <Option key={state} value={state}>
                      {toLower(state)}
                    </Option>
                    ))}
                </Select>
                )}
            />
            <Column
              title="Actions"
              key="actions"
              fixed="right"
              render={(_, feature: IClientFeature) => (
                <Space size="middle">
                  <Tooltip title="Edit">
                    <EditOutlined
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        onEditFeature(feature);
                      }}
                    />
                  </Tooltip>
                  {feature.state === ClientFeatureState.LIMITED && (
                    <Tooltip title="Bulk apply">
                      <SolutionOutlined
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();

                          onBulkApplyFeature(feature);
                        }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Delete">
                    <Popconfirm
                      title="Are you sure you want to delete this client feature?"
                      onConfirm={async (event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        await deleteClientFeatureById({
                          variables: {
                            id: feature.id,
                          },
                        });

                        refetch();
                      }}
                      onCancel={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      okText="Yes, delete"
                      cancelText="Cancel"
                      okButtonProps={{
                        danger: true,
                      }}
                      disabled={deleting}
                    >
                      <DeleteOutlined
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      />
                    </Popconfirm>
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
        )
      }
    </div>
  );
});

export default ClientFeatureList;
