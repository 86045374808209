import * as React from 'react';

import {
  Switch, Route,
  useRouteMatch,
} from 'react-router-dom';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy } = React;
const AdminToolsList = lazy(() => import('./AdminToolsList'));

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const AdminToolsView: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();

  return (
    <div className={props.className}>
      <Switch>
        <Route path={`${match.path}`} render={lazyLoadComponent(AdminToolsList)} />
      </Switch>
    </div>
  );
});

export default AdminToolsView;
