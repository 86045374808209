export const BRAND_CATEGORY_OPTIONS = [
  { value: 'selfhelp', label: 'Advice/Education' },
  { value: 'beauty', label: 'Beauty' },
  { value: 'fashion', label: 'Fashion' },
  { value: 'fitness', label: 'Fitness' },
  { value: 'food', label: 'Food/Drink' },
  { value: 'wellness', label: 'Health/Wellness' },
  { value: 'decor', label: 'Home/Design' },
  { value: 'kidsfamily', label: 'Kids/Family' },
  { value: 'pets', label: 'Pets' },
  { value: 'tech', label: 'Tech' },
  { value: 'travel', label: 'Travel' },
  { value: 'other', label: 'Other' },
] as const;

export type BrandCategory = typeof BRAND_CATEGORY_OPTIONS[number]['value'];
