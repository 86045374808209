import * as React from 'react';
import DeleteCreatorFromAspireForm from './DeleteCreatorFromAspireForm';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const AdminToolsViewList: React.FC<IProps> = React.memo((props) => (
  <div className={props.className}>
    <DeleteCreatorFromAspireForm />
  </div>
  ));

export default AdminToolsViewList;
