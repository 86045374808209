// @ts-strict-ignore

import * as React from 'react';
import Helmet from 'react-helmet';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';

import 'antd/dist/antd.css';
import { Spin } from 'antd';

import { appConfig } from '@frontend/app/config';

import { Home } from '@frontend/app/containers';
import { useAuth } from '@frontend/context/authContext';

import { LoginView } from './LoginView';

import styles from './App.scss';

/**
 * @extends {React.Component}
 */
const App: React.FunctionComponent<{}> = React.memo(() => {
  const {
    loading, isAuthenticated,
    token, authError, targetUrl,
    apolloClient,
    loginWithRedirect, logout,
  } = useAuth();

  return (
    <div className={styles.App}>
      <Helmet {...appConfig.app} {...appConfig.app.head} />
      {
        loading &&
        <Spin size="large" className={styles.spinner} />
      }
      {
        !loading && !isAuthenticated &&
        <LoginView
          error={authError}
          onSignIn={loginWithRedirect}
        />
      }
      {
        apolloClient && (
          <ApolloHooksProvider client={apolloClient}>
            <Home token={token} targetUrl={targetUrl} logout={logout} />
          </ApolloHooksProvider>
        )
      }
    </div>
  );
});

export default App;
