import 'css-chunk:src/app/containers/Home/ReportingView/ReportingListView.scss';export default {
  "ReportingListView": "_ReportingListView_1i8f9_2",
  "header": "_header_1i8f9_2",
  "addButton": "_addButton_1i8f9_8",
  "table": "_table_1i8f9_11",
  "nameCell": "_nameCell_1i8f9_11",
  "AddOrEditModal": "_AddOrEditModal_1i8f9_16",
  "select": "_select_1i8f9_16",
  "input": "_input_1i8f9_19",
  "inputLabel": "_inputLabel_1i8f9_23"
};