import * as React from 'react';
import {
 Button, Input, Form, Typography,
} from 'antd';
import { Modal, message } from '@revfluence/fresh';
import { DELETE_CREATOR_FROM_ASPIRE, FIND_MEMBERS_BY_EMAIL } from '@frontend/app/queries/members';
import { DeleteCreatorFromAspire, DeleteCreatorFromAspireVariables } from '@frontend/app/queries/types/DeleteCreatorFromAspire';
import { FindMembersByEmail, FindMembersByEmailVariables } from '@frontend/app/queries/types/FindMembersByEmail';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { logger } from '@common';
import styles from './DeleteCreatorFromAspireForm.scss';

const { useState, useCallback } = React;

const { Title, Text } = Typography;

/**
 * @type {React.FunctionComponent}
 */
const DeleteCreatorFromAspireForm: React.FC = React.memo(() => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const [form] = Form.useForm();
  const [email, setEmail] = useState<string>('');

  const { data: eligibleMemberData, refetch } = useQuery<FindMembersByEmail, FindMembersByEmailVariables>(FIND_MEMBERS_BY_EMAIL, {
    variables: { email },
    skip: !email,
    fetchPolicy: 'no-cache',

    onError(error) {
      logger.error(error);
      message.error(error.message);
    },
  });
  const [deleteCreatorFromAspire] = useMutation<DeleteCreatorFromAspire, DeleteCreatorFromAspireVariables>(DELETE_CREATOR_FROM_ASPIRE, {
    onCompleted: () => {
      message.success(form.getFieldValue('email'));
    },

    onError(error) {
      logger.error(error);
      message.error(error.message);
    },
  });

  const [open, setOpen] = useState<boolean>(false);
  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = () => {
    deleteCreatorFromAspire({ variables: { email: form.getFieldValue('email') } });
    closeModal();
  };

  const onFinishFailed = ({ errorFields }: { errorFields: { errors: string[] }[] }) => {
    message.error(errorFields[0].errors[0]);
    closeModal();
  };

  return (
    <div>
      <Title>Delete Creator Request</Title>
      <div style={{ wordWrap: 'break-word', maxWidth: window.innerWidth / 2 }}>
        <Text> This renames the creator email from their original to something with @DELETED_REQUEST.com at the end. Our expectation here is that the user data need to stay intact for brand reporting reasons, but if the creator re-auths, they should no longer see that data.</Text>
      </div>

      <Form
        form={form}
        onFinish={openModal}
        onFinishFailed={onFinishFailed}
        {...formItemLayout}
      >
        <div className={styles.form}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
            { required: true, message: "Please enter the creator's email." },
            { type: 'email', message: 'Please enter a valid email address.' },
            ]}
          >
            <Input placeholder="Enter the creator's email" />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={false}
          >
            Submit
          </Button>
          <Button
            type="primary"
            htmlType="button"
            loading={false}
            onClick={() => {
              console.log('>>>>> 2');
              console.log(form.getFieldValue('email'));
              setEmail(form.getFieldValue('email'));
              refetch();
            }}
          >
            Search
          </Button>
        </div>
      </Form>
      <Modal
        title="Delete Creator"
        okText="Delete Creator"
        cancelText="Cancel"
        centered
        zIndex={10000000}
        okButtonProps={{
        danger: true,
        }}
        open={open}
        confirmLoading={false}
        onOk={onSubmit}
        onCancel={closeModal}
        closable={false}
      >
        Delete all instance of
        {' '}
        {form.getFieldValue('email')}
        {' '}
        from Aspire(change email address)?
      </Modal>
      <div>
        <h3>Member List</h3>
        <ul>
          {(eligibleMemberData?.findMembersByEmail || []).map((item) => (
            <li key={item.id}>
              id=
              {item.id}
              {' '}
              name=
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});

export default DeleteCreatorFromAspireForm;
