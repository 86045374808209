import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { GET_ADMIN_CLIENT_ID_QUERY } from '@frontend/app/queries';
import { GetAdminClientIdQuery } from '@frontend/app/queries/types/GetAdminClientIdQuery';

type IOptions = QueryHookOptions<GetAdminClientIdQuery>;

export function useGetAdminClientId(options: IOptions = {}) {
  const { data: { adminClientId } = {} } = useQuery<GetAdminClientIdQuery>(
    GET_ADMIN_CLIENT_ID_QUERY,
    options,
  );

  return adminClientId;
}
