import * as React from 'react';
import {
  Drawer,
  Form,
  Button,
  Select,
  Space,
  Input,
  Tabs,
  message,
} from 'antd';
import { IClientFeature } from '@frontend/app/hooks';
import { useGetClients } from '@frontend/app/hooks/clients/useGetClients';
import { useAddFeaturesToClients } from '@frontend/app/hooks/useAddFeaturesToClients';

interface IProps {
  open: boolean;
  onRequestClose: () => void;
  selectedFeature: IClientFeature;
  features: IClientFeature[];
}

const { Option } = Select;
const { TextArea } = Input;

const BulkApplyFeatureDrawer: React.FC<IProps> = ({
  open,
  onRequestClose,
  selectedFeature,
  features,
}) => {
  const [form] = Form.useForm();
  const { clients, loading: loadingClients } = useGetClients();
  const { addFeaturesToClients, loading: applying } = useAddFeaturesToClients();
  const [inputMethod, setInputMethod] = React.useState<'select' | 'text'>('select');

  React.useEffect(() => {
    if (selectedFeature) {
      form.setFieldsValue({ featureIds: [selectedFeature.id] });
    }
  }, [selectedFeature, form]);

  const handleSubmit = async (values: { clientIds?: string[], clientIdsText?: string, featureIds: string[] }) => {
    const clientIds = inputMethod === 'select'
      ? values.clientIds
      : values.clientIdsText?.split(',').map((id) => id.trim()).filter(Boolean);

    try {
      await addFeaturesToClients({
        variables: {
          clientIds,
          featureIds: values.featureIds,
        },
      });
      message.success('Features applied successfully');
      form.resetFields();
      onRequestClose();
    } catch (error) {
      if (error instanceof Error) {
        message.error(`Failed to apply features: ${error.message}`);
      } else {
        message.error("Failed to apply features due to an unknown error.");
      }
    }
  };

  return (
    <Drawer
      title="Bulk Apply Feature"
      placement="right"
      onClose={onRequestClose}
      open={open}
      width={400}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="featureIds"
          label="Features"
          rules={[{ required: true, message: 'Please select at least one feature' }]}
        >
          <Select
            mode="multiple"
            placeholder="Select features"
            style={{ width: '100%' }}
            optionFilterProp="name"
          >
            {features?.map((feature) => (
              <Option key={feature.id} value={feature.id} name={feature.name}>
                {feature.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Tabs
          activeKey={inputMethod}
          onChange={(key) => setInputMethod(key as 'select' | 'text')}
          items={[
            {
              key: 'select',
              label: 'Select Clients',
              children: (
                <Form.Item
                  name="clientIds"
                  rules={[{ required: inputMethod === 'select', message: 'Please select at least one client' }]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select clients"
                    loading={loadingClients}
                    style={{ width: '100%' }}
                    optionFilterProp="name"
                  >
                    {clients?.map((client) => (
                      <Option key={client.id} value={client.id} name={client.name}>
                        {client.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ),
            },
            {
              key: 'text',
              label: 'Enter Client IDs',
              children: (
                <Form.Item
                  name="clientIdsText"
                  rules={[{ required: inputMethod === 'text', message: 'Please enter client IDs' }]}
                  help="Enter client IDs as comma-separated values"
                >
                  <TextArea
                    placeholder="e.g. 3zEY0YfVBB4Fv7PMwXXpJIvN072f11GT, AokF1bSFFlPvnBNbmFlk9zkA0MyWlEji, xypLC4N2zRdqWKuf84J58PJo7xcYCm2Q"
                    rows={4}
                  />
                </Form.Item>
              ),
            },
          ]}
        />

        <Space style={{ marginTop: 24 }}>
          <Button onClick={onRequestClose}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={applying}>
            Apply
          </Button>
        </Space>
      </Form>
    </Drawer>
  );
};

export default BulkApplyFeatureDrawer;
